<template>
	<!-- 仓库资料 -->
	<div class="warehouseData">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click.stop="dialogVisible = true, form = {}">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">目前仅支持“<a href="https://u.jd.com/iOYCBoa"
						target="_blank">深圳大趋智能</a>”厂家的打印机设备，该厂家打印机同时支持语音播报</span>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="码牌SN" prop="sn" align="center"></el-table-column>
				<el-table-column label="码牌名称" prop="remark" align="center"></el-table-column>
				<el-table-column label="小票打印" prop="print_name" align="center"></el-table-column>
				<el-table-column label="语音播报" prop="voice_name" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="260">
					<template slot-scope="scope">
						<el-button size="mini" @click.stop="downloadFn(scope.row)">下载</el-button>
						<el-button type="primary" size="mini" @click.stop="openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="'新增'" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="码牌名称" required>
					<el-input v-model="form.remark" placeholder="请输入码牌名称"></el-input>
				</el-form-item>
				<el-form-item label="小票打印">
					<el-select v-model="form.print_id" placeholder="请选择" style="width:100%">
						<el-option v-for="item in deviceList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="语音播报">
					<el-select v-model="form.voice_id" placeholder="请选择" style="width:100%">
						<el-option v-for="item in deviceList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				total: 0,
				page: 1,
				pageNum: 10,
				loading: true,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				plDialogVisible: false,
				form: {
					type: 'DQP7'
				},
				deviceList: []
			}
		},
		mounted() {
			this.getList()
			this.getDeviceList()
		},
		methods: {
			getDeviceList() {
				this.$http.post(`/erp/v1/device/lst`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.deviceList = data
						this.deviceList.unshift({
							id: 0,
							name: '不设置'
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			openDialog(item) {
				this.form = {
					...item
				}
				this.dialogType = 2
				this.dialogVisible = true
			},
			getList() {
				this.$http.post(`/erp/v1/code_plate/lst`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			downloadFn(item) {
				this.$alert('请到微信小程序下载收款二维码', '提示', {
					confirmButtonText: '确定',
					callback: action => {

					}
				});
			},
			// 确定
			confirmFn() {
				let url = this.dialogType == 1 ? '/erp/v1/code_plate/add' : '/erp/v1/code_plate/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},

			// 删除
			removeFn(item) {
				this.$confirm('确定要删除码牌【' + item.name + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/code_plate/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>